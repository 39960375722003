import { Icon } from '@/components/icons'
import { cn } from '@/utils/classes'
import { ShowMore } from '@/components/ui/show-more'
import GuestLayout from '@/layouts/guest-layout'
import { __, flashMessage } from '@/lib/utils'
import { Head, useForm } from '@inertiajs/react'
import { FormEventHandler, useEffect } from 'react'
import { toast } from 'sonner'
import { Button, buttonStyles, Card, Checkbox, Form, Link, Loader, TextField } from 'ui'

export default function Login({ status, canResetPassword }: { status?: string; canResetPassword: boolean }) {
    const { data, setData, post, processing, errors, reset } = useForm({
        email: '',
        password: '',
        remember: false,
    })

    useEffect(() => {
        return () => {
            reset('password')
        }
    }, [])

    const submit: FormEventHandler = (e) => {
        e.preventDefault()

        post(route('login'), {
            preserveState: true,
            onSuccess: (params) => {
                const flash = flashMessage(params)
                if (flash) {
                    // @ts-ignore
                    toast(flash.message)
                }
            },
        })
    }

    return (
        <>
            <Head title={__('Masuk')} />

            {status && <div className="mb-4 text-sm font-medium text-green-600">{status}</div>}
            <Card className="border-0 sm:border">
                <Card.Header>
                    <Card.Title>{__('Masuk')}</Card.Title>
                </Card.Header>
                <Form onSubmit={submit} validationErrors={errors}>
                    <Card.Content className="space-y-4">
                        <TextField
                            id="email"
                            type="email"
                            name="email"
                            autoComplete="email"
                            value={data.email}
                            autoFocus={true}
                            onChange={(value) => setData('email', value)}
                            isRequired
                            label={__('Surel')}
                            errorMessage={errors.email}
                        />
                        <TextField
                            id="password"
                            type="password"
                            name="password"
                            autoComplete="password"
                            value={data.password}
                            // autoFocus={true}
                            onChange={(value) => setData('password', value)}
                            isRequired
                            label={__('Kata Sandi')}
                            errorMessage={errors.password}
                        />
                        <div className="flex flex-row items-center justify-between">
                            <Checkbox name="remember" onChange={(value) => setData('remember', value)} id="remember">
                                {__('Ingatkan saya')}
                            </Checkbox>
                            {canResetPassword && (
                                <Link href={route('password.request')} className="text-sm">
                                    {__('Lupa kata sandi?')}
                                </Link>
                            )}
                        </div>
                    </Card.Content>
                    <Card.Footer className="flex flex-col space-y-4">
                        <Button name="submit" type="submit" isDisabled={processing} className="w-full">
                            {processing ? <Loader variant="ring" /> : __('Masuk')}
                        </Button>
                        <Link
                            href={route('register')}
                            className={cn(
                                buttonStyles({ appearance: 'solid', intent: 'secondary' }),
                                'w-full items-center justify-center',
                            )}
                        >
                            {__('Daftar')}
                        </Link>
                        <div className="py-1">
                            <ShowMore as="text" text={__('atau lanjutkan dengan')} />
                        </div>
                        <div className="flex w-full flex-col gap-4 md:flex-row">
                            <a
                                href={route('socialite.redirect', 'google')}
                                className={cn(
                                    buttonStyles({ appearance: 'solid', intent: 'secondary' }),
                                    'w-full items-center justify-center',
                                )}
                            >
                                <Icon iconName="Google" className="mr-2" />
                                Google
                            </a>
                            <a
                                href={route('socialite.redirect', 'github')}
                                className={cn(
                                    buttonStyles({ appearance: 'solid', intent: 'secondary' }),
                                    'w-full items-center justify-center',
                                )}
                            >
                                <Icon iconName="Github" className="mr-2" />
                                Github
                            </a>
                        </div>
                    </Card.Footer>
                </Form>
            </Card>
        </>
    )
}

Login.layout = (page: any) => <GuestLayout children={page} />
